<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="col-md-12">
      <div class="card">
        <b-tabs>
          <b-tab title="Voucher Detail" active>
            <div class="card-header">
              <h3 class="card-title">{{ title }}</h3>
            </div>
            <div class="card-body">
              <el-skeleton :loading="isLoading" :rows="6" animated />
              <b-form v-if="!isLoading" @submit.prevent="onSubmitVoucher">
                <b-form-group label="Merchant">
                  <el-select v-model="v$.form.merchant_id.$model" placeholder="Select merchant" class="mr-2 w-full" filterable remote :remote-method="searchMerchant">
                    <el-option
                      v-for="item in merchantList"
                      :key="item.merchant_id"
                      :label="item.merchant_name"
                      :value="item.merchant_id">
                    </el-option>
                  </el-select>
                  <b-form-invalid-feedback :state="!v$.form.merchant_id.$invalid" v-if="v$.form.merchant_id.$errors[0]">
                    <span> {{ v$.form.merchant_id.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Vendor">
                  <el-select v-model="v$.form.voucher_vendor.$model" placeholder="Select voucher vendor" class="mr-2 w-full">
                    <el-option
                      v-for="item in vendor"
                      :key="item.vendor_id"
                      :label="item.vendor_name"
                      :value="item.vendor_id">
                    </el-option>
                  </el-select>
                  <b-form-invalid-feedback :state="!v$.form.voucher_vendor.$invalid" v-if="v$.form.voucher_vendor.$errors[0]">
                    <span> {{ v$.form.voucher_vendor.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Voucher Name">
                  <el-input
                    v-model="v$.form.voucher_name.$model"
                    placeholder="Input voucher name"
                    type="text"/>
                  <b-form-invalid-feedback :state="!v$.form.voucher_name.$invalid" v-if="v$.form.voucher_name.$errors[0]">
                    <span> {{ v$.form.voucher_name.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Description">
                  <quill-editor v-model="v$.form.voucher_description.$model"
                    ref="myQuillEditor"
                    :options="editorOption">
                  </quill-editor>
                  <b-form-invalid-feedback :state="!v$.form.voucher_description.$invalid" v-if="v$.form.voucher_description.$errors[0]">
                    <span> {{ v$.form.voucher_description.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Terms and Condition">
                  <quill-editor v-model="v$.form.voucher_tnc.$model"
                    ref="myQuillEditor"
                    :options="editorOption">
                  </quill-editor>
                  <b-form-invalid-feedback :state="!v$.form.voucher_tnc.$invalid" v-if="v$.form.voucher_tnc.$errors[0]">
                    <span> {{ v$.form.voucher_tnc.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Base Price">
                  <el-input v-model="formattedVoucherBasePrice" placeholder="Rp.0"/>
                  <b-form-invalid-feedback :state="!v$.form.voucher_base_price.$invalid" v-if="v$.form.voucher_base_price.$errors[0]">
                    <span> {{ v$.form.voucher_base_price.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Sell Price">
                  <el-input v-model="formattedVoucherPrice" placeholder="Rp.0"/>
                  <b-form-invalid-feedback :state="!v$.form.voucher_price.$invalid" v-if="v$.form.voucher_price.$errors[0]">
                    <span> {{ v$.form.voucher_price.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Normal Price">
                  <el-input v-model="formattedVoucherNormalSellPrice" placeholder="Rp.0"/>
                  <b-form-invalid-feedback :state="!v$.form.voucher_normal_sell_price.$invalid" v-if="v$.form.voucher_normal_sell_price.$errors[0]">
                    <span> {{ v$.form.voucher_normal_sell_price.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Max. Booked">
                  <el-input
                    v-model="form.voucher_max_book"
                    placeholder="Input voucher max book"
                    type="number"/>
                </b-form-group>
                <b-form-group label="Enable Purchase from API">
                  <el-switch
                    active-text="Yes"
                    inactive-text="No"
                    v-model="form.enable_purchase_api"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </b-form-group>
                <b-form-group v-if="form.enable_purchase_api" label="API Product">
                  <el-select
                    filterable
                    :filter-method="handlerSearchVoucherSku"
                    :loading="loading.voucher_sku"
                    loading-text="Searching data..."
                    v-model="v$.form.voucher_sku_vendor_id.$model" placeholder="Select voucher vendor" class="mr-2 w-full">
                    <el-option
                      v-for="item in uv_voucher"
                      :key="item.id"
                      :label="item.sku_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                  <small class="text-brand font-weight-bold"><i>Choose product to linkage with this voucher.</i></small>
                  <b-form-invalid-feedback :state="!v$.form.voucher_sku_vendor_id.$invalid" v-if="v$.form.voucher_sku_vendor_id.$errors[0]">
                    <span> {{ v$.form.voucher_sku_vendor_id.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group v-if="form.enable_purchase_api" label="Limitation Type">
                  <el-select v-model="v$.form.voucher_purchase_duration.$model" placeholder="Select limitation type" class="mr-2 w-full">
                    <el-option
                      v-for="item in purchase_duration_options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <small class="text-brand font-weight-bold"><i>Limitation type to define whether it's limit by daily or monthly purchase.</i></small>
                  <b-form-invalid-feedback :state="!v$.form.voucher_purchase_duration.$invalid" v-if="v$.form.voucher_purchase_duration.$errors[0]">
                    <span> {{ v$.form.voucher_purchase_duration.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group v-if="form.enable_purchase_api" label="Limitation Amount">
                  <el-input
                    v-model="v$.form.voucher_max_purchase.$model"
                    placeholder="Input limit amount"
                    type="text"/>
                  <b-form-invalid-feedback :state="!v$.form.voucher_max_purchase.$invalid" v-if="v$.form.voucher_max_purchase.$errors[0]">
                    <span> {{ v$.form.voucher_max_purchase.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="SKU">
                  <el-input
                    v-model="form.voucher_sku"
                    placeholder="Input voucher SKU (optional)"
                    type="text"/>
                </b-form-group>
                <div class="row mb-2">
                  <div class="col-lg-6">
                    <b-form-group label="Voucher Banner">
                      <b-form-file
                          v-model="selected_file.voucher_banner"
                          :state="Boolean(selected_file.voucher_banner)"
                          class="mt-2 border-1 p-2"
                          accept=".png, .jpg, .jpeg"
                          @input="onChangeFile($event, 'voucher_banner')"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </b-form-group>
                    <b-form-invalid-feedback :state="!v$.form.voucher_banner.$invalid" v-if="v$.form.voucher_banner.$errors[0]">
                      <span> {{ v$.form.voucher_banner.$errors[0].$message }}</span>
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group label="Voucher Photo">
                      <b-form-file
                        v-model="selected_file.voucher_photo_url"
                        :state="Boolean(selected_file.voucher_photo_url)"
                        class="mt-2 border-1 p-2"
                        accept=".png, .jpg, .jpeg"
                        @input="onChangeFile($event, 'voucher_photo_url')"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      ></b-form-file>
                    </b-form-group>
                    <b-form-invalid-feedback :state="!v$.form.voucher_photo_url.$invalid" v-if="v$.form.voucher_photo_url.$errors[0]">
                      <span> {{ v$.form.voucher_photo_url.$errors[0].$message }}</span>
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <img ref="current_voucher_banner" :src="form.current_voucher_banner"/>
                  </div>
                  <div class="col-lg-6">
                    <img ref="current_voucher_photo_url" :src="form.current_voucher_photo_url"/>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-lg-6">
                    <b-form-group label="Voucher Deals Banner">
                      <b-form-file
                          v-model="selected_file.deals_banner"
                          :state="Boolean(selected_file.deals_banner)"
                          class="mt-2 border-1 p-2"
                          accept=".png, .jpg, .jpeg"
                          @input="onChangeFile($event, 'deals_banner')"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </b-form-group>
                    <b-form-invalid-feedback :state="!v$.form.deals_banner.$invalid" v-if="v$.form.deals_banner.$errors[0]">
                      <span> {{ v$.form.deals_banner.$errors[0].$message }}</span>
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <img ref="current_deals_banner" :src="form.current_deals_banner"/>
                  </div>
                </div>
                <!-- <b-form-group label="Set as Recommend Deal">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    v-model="form.is_recommend_deal"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </b-form-group> -->
                <b-form-group label="Status">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    active-text="Active"
                    inactive-text="Inactive"
                    v-model="form.voucher_status"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </b-form-group>
                <b-form-group label="Set as Special Promotion">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    active-text="Yes"
                    inactive-text="No"
                    v-model="form.is_special_deal"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </b-form-group>
                <b-form-group label="Set as Recommend Promotion">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    active-text="Yes"
                    inactive-text="No"
                    v-model="form.is_top_voucher"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </b-form-group>
                <b-form-group label="Set as MyBenefit Voucher">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    active-text="Yes"
                    inactive-text="No"
                    v-model="form.is_mybenefit"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </b-form-group>
                <div class="d-flex flex-row float-right mt-3 mb-3">
                  <el-button @click="onSubmitVoucher" :loading="loading.create_voucher" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
                  <el-button @click="$router.back()" size="small" class="mr-2">Cancel</el-button>
                  <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
                </div>
              </b-form>
            </div>
          </b-tab>
          <b-tab @click="page = 1; filter.status_filter = null; loadListDetail()" title="Voucher Code List">
            <div class="card-header justify-content-between">
              <h3 class="card-title">Code List</h3>
              <div class="flex flex-row mb-2 pr-2 float-right">
                <el-button @click="showModalUpload = true; is_voucher_refund = 0" type="primary" size="small"><font-awesome-icon icon="upload"/> Upload Voucher</el-button>
              </div>
            </div>
            <el-skeleton v-if="loading.detail" class="p-3" :loading="loading.detail" :rows="6" animated />
            <div v-if="!loading.detail" class="table-responsive">
              <table class="table card-table text-nowrap table-vcenter">
                <thead>
                  <tr>
                    <th width="25%">Voucher Name</th>
                    <th width="20%">User Name</th>
                    <th width="20%">User Email</th>
                    <th width="15%">Status</th>
                    <th width="25%">Upload Date</th>
                    <th width="25%">Expired Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in voucher_list.rows" :key="item.v_detail_id">
                    <td>{{ item.voucher.voucher_name }}</td>
                    <td>{{ item.user ? item.user.full_name : "-" }}</td>
                    <td>{{ item.user ? item.user.email : "-" }}</td>
                    <td>
                      <span class="tag" :class="item.status_color_str">{{ item.status_str }}</span>
                    </td>
                    <td>{{ item.upload_date_str }}</td>
                    <td>{{ item.expired_date_str }}</td>
                  </tr>
                </tbody>
              </table>
              <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
              <div class="card-footer d-flex justify-content-between">
                <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
                <div class="inline-table">
                  <b-pagination
                    class="table-cell"
                    @change="pageChangeHandler"
                    :per-page="per_page"
                    :limit="9"
                    :total-rows="total_rows"
                    v-model="page"
                  />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab @click="page = 1; filter.status_filter = 5; loadListDetail()" title="Auto Refund Stock">
            <div class="card-header justify-content-between">
                <h3 class="card-title">Stock List</h3>
                <div class="flex flex-row mb-2 pr-2 float-right">
                  <el-button @click="showModalUpload = true; is_voucher_refund = 1" type="primary" size="small"><font-awesome-icon icon="upload"/> Upload Voucher</el-button>
                </div>
            </div>
            <el-skeleton v-if="loading.detail" class="p-3" :loading="loading.detail" :rows="6" animated />
            <div v-if="!loading.detail" class="table-responsive">
              <table class="table card-table text-nowrap table-vcenter">
                <thead>
                  <tr>
                    <th width="25%">Voucher Name</th>
                    <th width="15%">Status</th>
                    <th width="25%">Upload Date</th>
                    <th width="25%">Expired Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in voucher_list.rows" :key="item.v_detail_id">
                    <td>{{ item.voucher.voucher_name }}</td>
                    <td>
                      <span class="tag" :class="item.status_color_str">{{ item.status_str }}</span>
                    </td>
                    <td>{{ item.upload_date_str }}</td>
                    <td>{{ item.expired_date_str }}</td>
                  </tr>
                </tbody>
              </table>
              <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
              <div class="card-footer d-flex justify-content-between">
                <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
                <div class="inline-table">
                  <b-pagination
                    class="table-cell"
                    @change="pageChangeHandler"
                    :per-page="per_page"
                    :limit="9"
                    :total-rows="total_rows"
                    v-model="page"
                  />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        <b-modal size="lg" v-model="showModalUpload" title="Upload Voucher" hide-footer @hide="closeModalUpload">
          <b-form @submit.prevent="uploadVocher" @reset="closeModalUpload">
            <b-form-group label="Voucher">
              <b-form-file
                v-model="selected_file.voucher"
                :state="Boolean(selected_file.voucher)"
                class="border-1 p-2"
                accept=".csv"
                ref="fileInput"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <b-form-invalid-feedback :state="false">
                <span>*only .csv file allowed</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <legend class="bv-no-focus-ring col-form-label pt-0">
                Prevent Duplicate
                <el-tooltip placement="top">
                  <div width="150px" slot="content">Prevent duplicate voucher code. Enable this option if you will upload unique voucher code</div>
                  <font-awesome-icon id="q-prevent" icon="question-circle"/>
                </el-tooltip>
              </legend>
              <el-switch
                :active-value="true"
                :inactive-value="false"
                active-text="Yes"
                inactive-text="No"
                v-model="form_upload_voucher.prevent_duplicate"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </b-form-group>
            <table v-if="preview_csv.length > 0" class="table table-vcenter">
              <thead>
                <tr>
                  <th width="30%">Code</th>
                  <th>Start Date</th>
                  <th>Expired Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in preview_csv" :key="'p' + index">
                  <td class="td-truncate">
                    <div class="text-truncate">{{ item.code }}</div>
                  </td>
                  <td>{{ item['start date'] }}</td>
                  <td>{{ item['expired date'] }}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex flex-row float-right">
              <el-button @click="confirmUploadVoucher" :loading="loading.upload_voucher" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
              <el-button @click="closeModalUpload" size="small" class="mr-2">Cancel</el-button>
              <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-lonely-if */
import moment from 'moment';
import { cloneDeep } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { quillEditor } from 'vue-quill-editor';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import {
  required, maxLength, numeric, requiredIf,
} from '@vuelidate/validators';
import {
  DETAIL_VOUCHERS, UPDATE_VOUCHERS, VOUCHER_LIST, UPLOAD_VOUCHERS, CREATE_VOUCHERS, VOUCHER_VENDOR,
  VOUCHER_LIST_UV,
} from '@/store/modules/vouchers';
import { GET_MERCHANTS } from '@/store/modules/merchants';
import emptyStateImage from '@/assets/images/empty-state.png';
import base64File from '@/library/base64File';
import popupErrorMessages from '@/library/popup-error-messages';
import imageCompress from '@/library/vue-image-compression';

export default {
  name: 'Vouchers',
  metaInfo: {
    title: 'Vouchers',
  },
  components: {
    quillEditor,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyStateImage,
      showModalUpload: false,
      merchantList: [],
      merchant_id: '',
      selected_file: {
        voucher_banner: null,
        voucher_photo_url: null,
        voucher_banner_base64: null,
        voucher_photo_url_base64: null,
        deals_banner: null,
        deals_banner_base64: null,
        voucher: null,
      },
      filter_merchant_loading: false,
      form_upload_voucher: {
        prevent_duplicate: false,
        csv_base64: '',
      },
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: false,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      showModalCreate: false,
      modalType: 'add',
      loading: {
        create_voucher: false,
        detail: false,
        upload_voucher: false,
        voucher_sku: false,
      },
      form: {
        voucher_name: '',
        voucher_description: '',
        voucher_tnc: '',
        voucher_banner: '',
        voucher_photo_url: '',
        deals_banner: '',
        voucher_price: 0,
        voucher_normal_sell_price: 0,
        voucher_base_price: 0,
        voucher_sku: '',
        voucher_start_at: moment().format('YYYY-MM-DD'),
        voucher_end_at: moment().add(30, 'days').format('YYYY-MM-DD'),
        is_recommend_deal: 1,
        voucher_status: 1,
        merchant_id: '',
        voucher_vendor: '',
        voucher_max_book: null,
        is_mybenefit: 0,
        enable_purchase_api: false,
        voucher_sku_vendor_id: null,
        voucher_purchase_duration: null,
        voucher_max_purchase: null,
      },
      editorOption: {
        placeholder: 'Please input voucher description',
      },
      purchase_duration_options: [
        {
          text: 'Daily',
          value: 'day',
        },
        {
          text: 'Monthly',
          value: 'month',
        },
      ],
      voucher_list: {},
      preview_csv: [],
      vendor: [],
      uv_voucher: [],
      timeoutSearchVoucherSku: null,
      voucher_id: null,
      is_voucher_refund: 0,
      filter: {
        status_filter: '',
      },
    };
  },
  computed: {
    title() {
      return this.$route.params.action === 'add' ? 'New Voucher' : 'Edit Voucher';
    },
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.voucher_list.rows.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    formattedVoucherBasePrice: {
      get() {
        return this.formatNumber(this.form.voucher_base_price);
      },
      set(value) {
        this.form.voucher_base_price = this.parseFormattedNumber(value);
      },
    },
    formattedVoucherPrice: {
      get() {
        return this.formatNumber(this.form.voucher_price);
      },
      set(value) {
        this.form.voucher_price = this.parseFormattedNumber(value);
      },
    },
    formattedVoucherNormalSellPrice: {
      get() {
        return this.formatNumber(this.form.voucher_normal_sell_price);
      },
      set(value) {
        this.form.voucher_normal_sell_price = this.parseFormattedNumber(value);
      },
    },
  },
  validations() {
    return {
      form: {
        voucher_name: {
          required,
          maxLength: maxLength(255),
        },
        voucher_description: { required },
        voucher_tnc: { required },
        voucher_banner: { required },
        voucher_photo_url: { required },
        deals_banner: { required },
        voucher_price: { required },
        voucher_normal_sell_price: { required },
        voucher_base_price: { required },
        voucher_start_at: { required },
        voucher_end_at: { required },
        merchant_id: { required },
        voucher_vendor: { required },
        voucher_sku_vendor_id: {
          required: requiredIf(this.form.enable_purchase_api),
        },
        voucher_purchase_duration: {
          required: requiredIf(this.form.enable_purchase_api),
        },
        voucher_max_purchase: {
          required: requiredIf(this.form.enable_purchase_api),
          numeric,
        },
      },
    };
  },
  async mounted() {
    const loader = this.$loading.show();
    if (this.$route.params.action === 'edit') {
      this.voucher_id = this.$route.params.id;
      await this.getDetail();
    } else {
      if (this.$route.query.dup_source) {
        this.voucher_id = this.$route.query.dup_source;
        await this.getDetail();
      }
    }
    await this.getMerchantList();
    await this.getVoucherVendor();
    loader.hide();
    this.getVoucherUv();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      await this.$store.dispatch(DETAIL_VOUCHERS, this.voucher_id);
      this.isLoading = false;
      this.form = this.$store.getters.voucher;
      this.form.current_voucher_banner = this.form.voucher_banner;
      this.form.current_voucher_photo_url = this.form.voucher_photo_url;
      this.form.current_deals_banner = this.form.deals_banner;
      if (this.form.voucher_sku_vendor_id) {
        this.$set(this.form, 'enable_purchase_api', true);
        // this.form.enable_purchase_api = true;
      } else {
        this.$set(this.form, 'enable_purchase_api', false);
      }
    },
    async onChangeFile(event, target) {
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        try {
          this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
          this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
          this.form[target] = this.selected_file[`${target}_base64`];
        } catch (error) {
          console.log(error);
        }
      }
    },
    onSubmitVoucher() {
      if (this.$route.params.action === 'add') {
        this.submitAddVoucher();
      } else {
        this.submitEditVoucher();
      }
    },
    submitEditVoucher() {
      const obj = cloneDeep(this.form);
      if (this.selected_file.voucher_photo_url_base64) obj.voucher_photo_url = this.selected_file.voucher_photo_url_base64;
      if (this.selected_file.voucher_banner_base64) obj.voucher_banner = this.selected_file.voucher_banner_base64;
      if (this.selected_file.deals_banner_base64) obj.deals_banner = this.selected_file.deals_banner_base64;
      if (!obj.voucher_max_book) obj.voucher_max_book = null;
      if (!obj.enable_purchase_api) {
        obj.voucher_max_purchase = null;
        obj.voucher_purchase_duration = null;
        obj.voucher_sku_vendor_id = null;
      }
      this.$confirm('Are you sure want to update this voucher?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.loading.create_voucher = true;
            await this.$store.dispatch(UPDATE_VOUCHERS, {
              id: this.$route.params.id,
              data: obj,
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Voucher updated',
              });
              this.getDetail();
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            this.loading.create_voucher = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    submitAddVoucher() {
      if (this.selected_file.voucher_photo_url_base64) this.form.voucher_photo_url = this.selected_file.voucher_photo_url_base64;
      if (this.selected_file.voucher_banner_base64) this.form.voucher_banner = this.selected_file.voucher_banner_base64;
      if (this.selected_file.deals_banner_base64) this.form.deals_banner = this.selected_file.deals_banner_base64;
      this.v$.form.$touch();
      // console.log(this.v$.form.$errors);
      if (this.v$.form.$error) return;
      if (!this.form.voucher_max_book) this.form.voucher_max_book = null;
      this.$confirm('Are you sure want to create new voucher?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.loading.create_voucher = true;
            await this.$store.dispatch(CREATE_VOUCHERS, this.form).then(({ data }) => {
              // console.log(data);
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Voucher created',
              });
              this.$router.push({
                name: 'Edit Voucher',
                params: {
                  id: data.voucher.voucher_id,
                  action: 'edit',
                },
              });
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            this.loading.create_voucher = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    searchHandler() {
      this.page = 1;
      if (this.merchant_id !== null) {
        this.search_by = 'merchant_id';
        this.search_keyword = this.merchant_id;
      }
      this.getList();
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    async getMerchantList() {
      this.filter_merchant_loading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        search_by: 'merchant_name',
        search_keyword: this.search_merchant_keyword,
      }).catch(() => {});
      this.merchantList = [];
      const { rows } = this.$store.getters.merchants;
      this.merchantList.push(...rows);
      if (this.voucher_id) {
        this.merchantList.push({
          merchant_name: this.form.merchant.merchant_name,
          merchant_id: this.form.merchant_id,
        });
      }
      this.filter_merchant_loading = false;
    },
    pageChangeHandler(page) {
      this.page = page;
      this.loadListDetail();
    },
    async loadListDetail() {
      this.loading.detail = true;
      await this.$store.dispatch(VOUCHER_LIST, {
        id: this.$route.params.id,
        query: {
          page: this.page,
          per_page: this.per_page,
          status_filter: this.filter.status_filter,
        },
      });
      this.voucher_list = this.$store.getters.voucher_list;
      this.total_rows = this.voucher_list.count;
      this.loading.detail = false;
    },
    closeModalUpload() {
      this.showModalUpload = false;
      this.preview_csv = [];
      this.form_upload_voucher = {
        csv_base64: '',
        prevent_duplicate: false,
      };
    },
    confirmUploadVoucher() {
      if (!this.selected_file.voucher) {
        this.$message({
          title: 'Oops',
          type: 'warning',
          message: 'File csv required',
          showClose: true,
        });
        return;
      }
      this.$confirm('You will upload voucher code. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.loading.upload_voucher = true;
            const data = new FormData();
            data.append('file', this.selected_file.voucher);
            data.append('prevent_duplicate', this.form_upload_voucher.prevent_duplicate);
            data.append('voucher_refund', this.is_voucher_refund);

            await this.$store.dispatch(UPLOAD_VOUCHERS, {
              id: this.$route.params.id,
              data,
            })
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Voucher uploaded',
                });
                this.closeModalUpload();
                this.loadListDetail();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            this.loading.upload_voucher = false;
            return;
          }
          done();
        },
      });
    },
    async onChangeFileVoucher() {
      const str = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(this.selected_file.voucher);
        reader.onload = () => resolve(reader.result.replace(/\\r\\n|\\r|\\n|\\n\\r/igm, ''));
        reader.onerror = (error) => reject(error);
      });
      const csv_split = str.split('\n');
      /* eslint-disable no-param-reassign */
      const header = csv_split[0].split(/;|,/ig).map((v) => {
        v = v.replace(/\\r\\n|\\r|\\n|\\n\\r/ig, '').replace('\r', '');
        return v;
      });
      const obj = [];
      for (let i = 1; i <= 5; i += 1) {
        const obj_to_push = {};
        const str_split = csv_split[i].split(/;|,/ig);
        header.forEach((k, ki) => {
          obj_to_push[k] = str_split[ki];
        });
        obj.push(obj_to_push);
      }
      this.preview_csv = obj;
      this.form_upload_voucher.csv_base64 = await base64File(this.selected_file.voucher);
      if (!this.form_upload_voucher.csv_base64) {
        this.$message({
          title: 'Oops',
          type: 'warning',
          message: 'Failed read csv file.',
        });
        this.$refs.fileInput.reset();
      }
    },
    async getVoucherVendor() {
      await this.$store.dispatch(VOUCHER_VENDOR, {});
      this.vendor = this.$store.getters.vendors.rows;
    },
    async getVoucherUv(search_keyword = '') {
      this.loading.voucher_sku = true;
      const { data } = await this.$store.dispatch(VOUCHER_LIST_UV, {
        search_by: 'sku_name',
        search_keyword,
      });
      this.loading.voucher_sku = false;
      this.uv_voucher = data.rows;
    },
    handlerSearchVoucherSku(value) {
      clearTimeout(this.timeoutSearchVoucherSku);
      this.timeoutSearchVoucherSku = setTimeout(() => {
        this.getVoucherUv(value);
      }, 1000);
    },
    formatNumber(value) {
      if (typeof value !== 'string') {
        value = String(value);
      }
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
    parseFormattedNumber(value) {
      return value.replace(/,/g, '');
    },
  },
};
</script>